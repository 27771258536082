export const notificationHooks = {

    methods: {

        async showErrorMessage(titleText, errorText)
        {
            this.$bvToast.toast(errorText,
                {
                    title: titleText,
                    autoHideDelay: 5000,
                    variant: "danger",
                    appendToast: true,
                    solid: true
                });
        },

        async showSuccessMessage(titleText, messageText)
        {
            this.$bvToast.toast(messageText,
                {
                    title: titleText,
                    autoHideDelay: 5000,
                    variant: "success",
                    appendToast: true,
                    solid: true
                });
        }

    }
};
