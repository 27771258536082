<template>
    <div class="content-container">

        <v-alert :value="showError" border="top" color="red" dismissible type="error" transition="scale-transition">{{downloadError}}</v-alert>

        <b-button v-if="!showTaxWizard" class="btn mb-2 btn-primary" size="sm" style="width:150px;" @click="openTaxWizard()">
            <font-awesome-icon :icon="['fas', 'plus-square']" color="white" />
            <span class="pl-2" style="color:white; font-weight:bold;">Add New Tax File</span>
        </b-button>

        <span class="ml-4" v-if="!hasSubscription" style="color:var(--var-theme-color) !important; font-weight:bold; font-size:small;">Upgrade to premium access to unlock all features</span>

        <!-- Tax Wizard -->
        <b-card v-if="showTaxWizard" header-bg-variant="dark" header-text-variant="white" class="cardHeader mb-2" header-class="customCardHeader">

            <template #header>
                <span>Add New Tax File</span>
            </template>

            <b-card-body style="padding:0px; margin:0px;">

                <div style="width:100%;">
                    <TaxFileWizard @addUpload="uploadAdded" @cancelUpload="uploadCanceled" />
                </div>

            </b-card-body>
        </b-card>

        <!-- Main Form -->
        <div v-show="showMainForm">

            <!-- Summary -->
            <b-card class="cardHeader mb-2" header-bg-variant="dark" header-text-variant="white" header-class="customCardHeader">

                <template #header style="border-width:10px; ">
                    <span>Uploaded Tax Files</span>
                </template>

                <b-card-body style="padding:0px; margin:0px; max-height:23vh !important; overflow-y: auto;">

                    <v-data-table :headers="taxSummaryFields"
                                  :items="taxSummary"
                                  :hide-default-footer="true"
                                  :disable-pagination="true"
                                  :item-class="getDefaultRowStyle"
                                  class="elevation-5 mb-5 secondary"
                                  dark>

                        <template v-slot:[`item.uncovered`]="props">
                            <span style="display:inline-block; width:30px;"> {{props.item.uncovered}}</span>
                            <b-button v-if="props.item.uncovered > 0" class="btn-secondary ml-2" @click="showUncoveredTransactions(props.item.uploadId)" size="sm">
                                <b-spinner v-if="!props.item.isComplete" type="grow" small variant="warning"></b-spinner>
                                <font-awesome-icon v-if="props.item.isComplete" :icon="['fas', 'check-circle']" color="#33ff33" />
                                <span class="ml-2" style="color:white; font-size:small;">View/Correct</span>
                            </b-button>
                        </template>
                        <template v-slot:[`item.edit`]="props">
                            <b-button v-if="props.item.exchange === 'Manual'" class="btn ml-2" @click="editUpload(props.item.uploadId)" size="sm" title="Manually edit With Stick-Shift">
                                <font-awesome-icon :icon="['fas', 'wrench']" color="white" />
                            </b-button>
                        </template>
                        <template v-slot:[`item.delete`]="props">
                            <b-button class="btn ml-2" @click="deleteUpload(props.item.uploadId)" size="sm" title="Delete exchange data">
                                <font-awesome-icon :icon="['fas', 'trash-alt']" color="white" />
                            </b-button>
                        </template>

                    </v-data-table>

                </b-card-body>

                <!-- Download Button -->
                <div class="float-left mb-4">
                    <b-button class="btn-primary mt-4" size="sm" style="width:150px;" @click="download()" :disabled="!hasSubscription" :title="downloadFileTitle">
                        <font-awesome-icon v-if="!hasSubscription" :icon="['fas', 'lock']" color="white" class="mr-2" />
                        <font-awesome-icon v-if="hasSubscription" :icon="['fas', 'file-export']" color="white" />
                        <span class="pl-2" style="color:white;">Download CSV</span>
                    </b-button>
                </div>

            </b-card>

            <!-- Transactions/Logs/Charts/Payment -->
            <div>
                <b-card no-body style="border-width:0px; border-radius:20px;">
                    <b-tabs card v-model="selectedTabIndex">
                        <b-tab title-link-class="bg-success text-light small mr-1">
                            <template #title>
                                <div title="Transactions">
                                    <font-awesome-icon v-if="!hasSubscription" :icon="['fas', 'lock']" color="white" class="ml-2" />
                                    <font-awesome-icon v-if="hasSubscription" :icon="['fas', 'coins']" color="white" class="ml-2" />
                                    <i class="ml-2">Transactions</i>
                                </div>
                            </template>
                            <b-card-text>
                                <div v-if="!hasSubscription">
                                    <div class="desktopTransactionScroll pt-2 ml-2" style="color:black; min-height:15vh !important;">
                                        <span style="color:black; font-weight:bold;" class="pr-2">You can see all your transactions sorted once you upgrade for only $8.</span>
                                    </div>
                                </div>
                                <div v-if="hasSubscription" class="desktopTransactionScroll">

                                    <v-data-table :headers="transactionFields"
                                                  :items="formattedRecords"
                                                  :hide-default-footer="true"
                                                  :disable-pagination="true"
                                                  fixed-header
                                                  height="35vh"
                                                  :item-class="getTransactionRowBackground"
                                                  class="elevation-5 mb-5 secondary"
                                                  dark>
                                    </v-data-table>

                                </div>
                            </b-card-text>
                        </b-tab>
                        <b-tab title-link-class="bg-info text-light small mr-1">
                            <template #title>
                                <div title="Logs">
                                    <font-awesome-icon v-if="!hasSubscription" :icon="['fas', 'lock']" color="white" class="ml-2" />
                                    <font-awesome-icon v-if="hasSubscription" :icon="['fas', 'bars']" color="white" class="ml-2" />
                                    <i class="ml-2">Logs</i>
                                </div>
                            </template>
                            <b-card-text>
                                <div v-if="!hasSubscription">
                                    <div class="desktopTransactionScroll pt-2 ml-2" style="color:black; min-height:15vh !important;">
                                        <span style="color:black; font-weight:bold;" class="pr-2">Yes! Logs. You can see all what is happening. Upgrade for only $8</span>
                                    </div>
                                </div>
                                <div v-if="hasSubscription">
                                    <span style="color:black; font-weight:bold; font-size:small;" class="pr-2">Asset Filter</span>
                                    <b-form-select v-model="selectedAssetLog" :options="allAssets" style="width:150px; font-size:14px;" class="mb-1"></b-form-select>
                                    <hr style="border-top: 1px solid blue; margin:10px;" />
                                    <div class="desktopLogScroll">

                                        <v-data-table :headers="logFields"
                                                      :items="filteredLogs"
                                                      :hide-default-footer="true"
                                                      :disable-pagination="true"
                                                      fixed-header
                                                      height="30vh"
                                                      :item-class="getLogRowBackground"
                                                      class="elevation-5 mb-5 secondary"
                                                      dark>
                                        </v-data-table>

                                    </div>
                                </div>
                            </b-card-text>
                        </b-tab>
                        <b-tab lazy title-link-class="bg-primary text-light small mr-1">
                            <template #title>
                                <div title="Charts">
                                    <font-awesome-icon :icon="['fas', 'chart-line']" color="white" class="ml-2" />
                                    <i class="ml-2">Charts</i>
                                </div>
                            </template>
                            <span style="color:black; font-weight:bold;" class="ml-3">{{chartTypeLabel}}</span>
                            <div class="chartButtonContainer">
                                <b-button class="btn-dark" size="sm" @click="selectChartType('pie')" title="Gain/Loss Distribution Pie Chart">
                                    <font-awesome-icon :icon="['fas', 'chart-pie']" class="fa-lg" color="white" />
                                </b-button>
                                <b-button class="btn-dark ml-1" size="sm" @click="selectChartType('bar')" title="Gain/Loss Distribution Bar Chart">
                                    <font-awesome-icon :icon="['fas', 'chart-bar']" class="fa-lg" color="white" />
                                </b-button>
                                <b-button class="btn-dark ml-1" size="sm" @click="selectChartType('line')" title="Gain/Loss Over Time">
                                    <font-awesome-icon :icon="['fas', 'chart-line']" class="fa-lg" color="white" />
                                </b-button>
                                <b-button class="btn-dark ml-1" size="sm" @click="selectChartType('table')" title="Show data as table" :disabled="!hasSubscription">
                                    <font-awesome-icon v-if="!hasSubscription" :icon="['fas', 'lock']" class="fa-lg" color="white" />
                                    <font-awesome-icon v-if="hasSubscription" :icon="['fas', 'table']" class="fa-lg" color="white" />
                                </b-button>
                                <div class="clearfix"></div>
                            </div>
                            <br />
                            <div v-if="showCharts" class="desktopChartsScroll" style="background-color:white;">
                                <GainLossPieChart v-if="chartType == 'pie'" ref="gainLossPieChart" :TaxRecords="formattedRecords" :Width="320" :Height="320" />
                                <GainLossBarChart v-if="chartType == 'bar'" ref="gainLossBarChart" :TaxRecords="formattedRecords" :Width="320" :Height="320" />
                                <GainLossLineChart v-if="chartType == 'line'" ref="gainLossLineChart" :TaxRecords="formattedRecords" :Width="320" :Height="320" />
                                <GainLossTableChart v-if="chartType == 'table'" ref="gainLossTableChart" :TaxRecords="formattedRecords" />
                            </div>
                        </b-tab>
                        <b-tab v-if="!hasSubscription" title-link-class="bg-danger text-light small mr-1">
                            <template #title>
                                <div title="Payment">
                                    <font-awesome-icon :icon="['fas', 'arrow-right']" color="white" class="fa-lg ml-2 iconBlink" />
                                    <font-awesome-icon :icon="['fas', 'credit-card']" color="white" class="ml-2" />
                                    <i class="ml-2">Payment</i>
                                    <font-awesome-icon :icon="['fas', 'arrow-left']" color="white" class="fa-lg ml-2 iconBlink" />
                                </div>
                            </template>
                            <b-card-text>
                                <div class="desktopTransactionScroll" style="color:black; min-height:30vh !important;">
                                    <div style="color:black; font-weight:bold;" class="ml-5">Unlock all features with premium access for only $8.00</div>

                                    <div class="ml-4 my-4 featureListItem">
                                        <v-icon color="green darken-2"> mdi-file-download </v-icon>
                                        <span class="pl-2">Download your complete TurboTax export file</span>
                                    </div>

                                    <div class="ml-4 my-4 featureListItem">
                                        <v-icon color="blue darken-2"> mdi-file-table </v-icon>
                                        <span class="pl-2">View all transactions and logs records in the order they were processed</span>
                                    </div>

                                    <div class="ml-4 my-4 featureListItem">
                                        <v-icon color="red darken-2"> mdi-file-chart</v-icon>
                                        <span class="pl-2">View additional gain/loss breakdowns</span>
                                    </div>

                                    <div class="ml-4 my-4 featureListItem">
                                        <v-icon color="purple darken-2"> mdi-timetable</v-icon>
                                        <span class="pl-2">Unlimited access for 30 days.</span>
                                    </div>

                                    <div>
                                        <b-button class="btn mt-2 ml-5 btn-primary" size="sm" style="width:200px;" @click="buySubscription()">
                                            <font-awesome-icon :icon="['fas', 'shopping-cart']" color="white" />
                                            <span class="pl-2" style="color:white; font-weight:bold;">Buy Premium Access</span>
                                        </b-button>
                                        <font-awesome-icon :icon="['fas', 'mouse-pointer']" color="black" class="fa-lg ml-2 iconBlink" style="position:relative; left:-20px; top:20px;" />
                                    </div>

                                </div>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </div>
        </div>

        <UncoveredTransactionsForm ref="uncoveredTransactionsForm" @modalClosed="uncoveredTransactionModalClosed" @correctionsUpdated="uncoveredTransactionModalSaved" />
        <LegalDisclaimerForm ref="legalDisclaimerDialog" @acceptTerms="termsAccepted" />
        <PaymentForm ref="paymentDialog" />
        <StickShift ref="stickShiftMyTaxesModal"
                    :TaxYear="stickShiftTaxYear"
                    :Exchange="stickShiftExchange"
                    :TaxMethod="stickShiftTaxMethod"
                    :UploadId="stickShiftUploadId"
                    :ManualExchange="stickShiftManualExchange"
                    @processSuccess="stickShiftMyTaxesSuccess" />
    </div>
</template>

<script>

    const TaxFileWizard = () => import('@/components/tax-file-wizard');
    const LegalDisclaimerForm = () => import('@/components/legal-disclaimer-form');
    const PaymentForm = () => import('@/components/payment-form');
    const StickShift = () => import('@/components/stick-shift');
    const UncoveredTransactionsForm = () => import('@/components/uncovered-transactions-form');
    const GainLossPieChart = () => import('@/components/charts/gain-loss-pie-chart');
    const GainLossBarChart = () => import('@/components/charts/gain-loss-bar-chart');
    const GainLossLineChart = () => import('@/components/charts/gain-loss-line-chart');
    const GainLossTableChart = () => import('@/components/charts/gain-loss-table-chart');
    import { notificationHooks } from '../shared/vue-mixin';
    import { downloadHelper } from '@/shared/download-helper';
    import { serviceHelper } from '../shared/service-helper';
    import { mapGetters, mapActions } from 'vuex';
    import { cloneDeep } from 'lodash';
    import { displayShortDateYearFormat } from '../shared/constants';
    import { format } from 'date-fns';

    export default {
        name: 'MyTaxes',

        /* COMPONENT DATA  */
        data() {
            return {
                downloadError: "",
                showError: false,
                selectedUploadId: null,
                selectedAssetLog: "All Assets",
                showTaxWizard: false,
                showUncoveredModal: false,
                selectedTabIndex: 0,
                chartType: "pie",
                showCharts: true,
                acceptedTerms: false,
                turboTaxRecords: [],
                logs: [],
                transactionFields: [
                    { value: 'assetAmount', text: "Asset Amount", sortable: false },
                    { value: 'assetName', text: "Asset Name", sortable: false },
                    { value: 'receivedDate', text: "Received Date", sortable: false },
                    { value: 'dateSold', text: "Date Sold", sortable: false },
                    { value: 'proceeds', text: "Proceeds", sortable: false },
                    { value: 'costBasis', text: "Cost Basis", sortable: false },
                    { value: 'gain', text: "Gain", sortable: false },
                    { value: 'type', text: "Type", sortable: false },
                ],
                taxSummaryFields: [
                    { text: 'Tax Year', value: 'year', sortable: false },
                    { text: 'Exchange', value: 'exchangeText', sortable: false },
                    { text: 'Accounting Method', value: 'method', sortable: false },
                    { text: 'Total Gain/Loss', value: 'gain', sortable: false },
                    { text: 'Uncovered Transactions', value: 'uncovered', sortable: false },
                    { text: '', value: 'edit', align: 'center', width: '50px', sortable: false },
                    { text: '', value: 'delete', align: 'center', width: '50px', sortable: false }, 
                ],
                logFields: [
                    { value: 'asset', text: "Asset", sortable: false },
                    { value: 'transactionType', text: "Type", sortable: false },
                    { value: 'quantity', text: "Quantity", sortable: false },
                    { value: 'price', text: "Price", sortable: false },
                    { value: 'totalCost', text: "Total Cost", sortable: false },
                    { value: 'timestamp', text: "Date", sortable: false },
                    { value: 'remainingBalance', text: "Balance", sortable: false },
                ],
                stickShiftTaxYear: null,
                stickShiftExchange: null,
                stickShiftTaxMethod: null,
                stickShiftUploadId: null,
                stickShiftManualExchange: null,
            };
        },

        /* PROPS */
        props: {
            Action: {
                type: String,
                default: () => '',
            }
        },

        /* MIXINS */
        mixins: [notificationHooks],

        /* LIFE CYCLE METHODS  */
        async mounted() {

            //Pull stored information from state and populate form
            this.loadDataFromStore();

            //Default to charts tab if user does not have a subscription or default to payment
            //tab and open up payment modal if pressed from "Buy" action on prices section.
            this.$nextTick(function () {
                if (this.Action === "subscribe") {
                    this.selectedTabIndex = 3;
                    this.buySubscription();
                }
                else {
                    if (!this.hasSubscription)
                        this.selectedTabIndex = 2;
                }
            });
        },

        /* CHILD COMPONENTS  */
        components: {
            TaxFileWizard,
            UncoveredTransactionsForm,
            GainLossPieChart,
            GainLossBarChart,
            GainLossLineChart,
            GainLossTableChart,
            LegalDisclaimerForm,
            PaymentForm,
            StickShift
        },

        /* WATCHERS */
        watch: {
            toggleCount: async function (val, oldVal) {
                this.forceChartRedraw();
            }
        },

        /* COMPONENT METHODS  */
        methods: {
            ...mapActions(['setTaxInfoAction', 'addTaxInfoAction', 'updateTaxCorrectionsAction']),

            loadDataFromStore() {

                var allTaxRecords = [];
                var allLogs = [];
                var allManualCorrections = [];
                var allUncoveredTransactions = [];

                if (this.currentUserData == null || this.currentUserData == undefined)
                    return;

                if (this.currentUserData.taxData !== null && this.currentUserData.taxData !== undefined) {

                    this.currentUserData.taxData.forEach(
                        uploadSet => {
                            allTaxRecords = allTaxRecords.concat(uploadSet.taxRecords);
                            allLogs = allLogs.concat(uploadSet.logs);
                            allManualCorrections = allManualCorrections.concat(uploadSet.manualCorrections);
                            allUncoveredTransactions = allUncoveredTransactions.concat(uploadSet.uncoveredTransactions);
                        });

                    //Force refresh when loading data from store
                    this.turboTaxRecords = cloneDeep(allTaxRecords); //State will be mutated if this isn't cloned
                    this.logs = allLogs;
           
                    if (allLogs.length === 0 ) {
                        //Auto show the add new tax wizard if no records exist (Logs imply tax records or uncovered transactions)
                        this.showTaxWizard = true;
                    }

                }
                else {
                    //Auto show the add new tax wizard if no records exist
                    this.showTaxWizard = true;
                }
            },

            uploadAdded(response, stickShiftRecords) {

                //Assign Ids to uncovered transactions
                const uncoveredTransactions = response.uncoveredTransactions.map(function (transaction, index) {
                    transaction.id = index;
                    transaction.spot = "???";
                    return transaction;
                });

                var newTaxData = {
                    uploadId: response.uploadId,
                    taxRecords: response.records,
                    logs: response.logs,
                    taxYear: response.taxYear,
                    taxMethod: response.taxMethod,
                    exchange: response.exchange,
                    manualExchange: response.manualExchange,
                    uncoveredTransactions: uncoveredTransactions,
                    manualCorrections: [],
                    stickShiftRecords: stickShiftRecords
                };

                this.addTaxInfoAction({ taxData: newTaxData }); //Call store

                //Tables/charts are formatted per the collection of all records so concatenate to entire result
                var allTaxRecords = [];
                var allLogs = [];
                this.currentUserData.taxData.forEach(
                    uploadSet => {
                        allTaxRecords = allTaxRecords.concat(uploadSet.taxRecords);
                        allLogs = allLogs.concat(uploadSet.logs);
                    });

                this.turboTaxRecords = cloneDeep(allTaxRecords);
                this.logs = allLogs;

                //Hide the wizard after successful add (Removes component state data)
                this.showTaxWizard = false;

                //Reset all charts
                this.resetAllCharts();
            },

            uploadCanceled() {
                if (this.currentUserData.taxData.length > 0) {
                    this.showTaxWizard = false;
                }
            },

            deleteUpload(value) {

                //TODO: Create store action to delete single tax upload
                var records = this.currentUserData.taxData.filter(x => x.uploadId !== value);
                this.setTaxInfoAction({ taxData: records }); //Call store
                this.loadDataFromStore();
            },

            editUpload(value) {
                var taxUpload = cloneDeep(this.currentUserData.taxData.find(x => x.uploadId === value));
                if (taxUpload !== null && taxUpload !== undefined) {
                    this.stickShiftTaxYear = taxUpload.taxYear;
                    this.stickShiftExchange = taxUpload.exchange;
                    this.stickShiftTaxMethod = taxUpload.taxMethod;
                    this.stickShiftUploadId = taxUpload.uploadId;
                    this.stickShiftManualExchange = taxUpload.manualExchange;
                    this.$refs.stickShiftMyTaxesModal.ShowModal(null, taxUpload.stickShiftRecords, taxUpload.uncoveredTransactions);
                }       
            },

            stickShiftMyTaxesSuccess(response, stickShiftRecords) {
                var uploadId = response.uploadId;

                //Assign Ids to uncovered transactions
                const uncoveredTransactions = response.uncoveredTransactions.map(function (transaction, index) {
                    transaction.id = index;
                    transaction.spot = "???";
                    return transaction;
                });

                var updatedTaxData = {
                    uploadId: response.uploadId,
                    taxRecords: response.records,
                    logs: response.logs,
                    taxYear: response.taxYear,
                    taxMethod: response.taxMethod,
                    exchange: response.exchange,
                    manualExchange: response.manualExchange,
                    uncoveredTransactions: uncoveredTransactions,
                    manualCorrections: [],
                    stickShiftRecords: stickShiftRecords
                };

                //Remove old uploadID
                var records = this.currentUserData.taxData.filter(x => x.uploadId !== uploadId);

                //Re-add new tax data to existing collection
                records.push(updatedTaxData);
                this.setTaxInfoAction({ taxData: records });

                //Tables/charts are formatted per the collection of all records so concatenate to entire result
                var allTaxRecords = [];
                var allLogs = [];
                this.currentUserData.taxData.forEach(
                    uploadSet => {
                        allTaxRecords = allTaxRecords.concat(uploadSet.taxRecords);
                        allLogs = allLogs.concat(uploadSet.logs);
                    });

                this.turboTaxRecords = cloneDeep(allTaxRecords);
                this.logs = allLogs;

                //Reset all charts
                this.resetAllCharts();

                this.showSuccessMessage("CryptoTaxSpace", "Your tax records have been successfully processed.");
            },

            download() {

                //Validate all uncovered transactions have been corrected
                var isComplete = true;
                this.taxSummary.forEach(
                    summary => {
                        if (summary.isComplete == false)
                            isComplete = false;
                    });

                if (isComplete) {
                    //Validation passed, show the legal disclaimer before creating the account
                    if (!this.acceptedTerms)
                        this.$refs.legalDisclaimerDialog.ShowModal();
                    else
                        downloadHelper.downloadTurboTaxFile(this.formattedRecords);
                }
                else {
                    this.showError = true;
                    this.downloadError = "All uncovered transactions must be reviewed before you can download your export.";
                    setTimeout(() => {
                        this.showError = false
                    }, 15000)
                }
            },

            termsAccepted() {
                this.acceptedTerms = true;
                downloadHelper.downloadTurboTaxFile(this.formattedRecords);
            },

            selectChartType(type) {
                this.chartType = type;
            },

            openTaxWizard() {
                this.showTaxWizard = true;
            },

            showUncoveredTransactions(uploadId) {

                /* Get Transaction Data Start */
                var transactionData = {
                    uploadId: null,
                    uncoveredTransactions: [],
                    manualCorrections: []
                };

                var taxUpload = cloneDeep(this.currentUserData.taxData.find(x => x.uploadId === uploadId));
                if (taxUpload !== null && taxUpload !== undefined) {
                    if (taxUpload.uncoveredTransactions.length > 0) {
                        transactionData.uploadId = taxUpload.uploadId;
                        transactionData.uncoveredTransactions = taxUpload.uncoveredTransactions;
                        transactionData.manualCorrections = taxUpload.manualCorrections;
                    }
                }

                /* Get Transaction Data End */
                this.selectedUploadId = uploadId;
                this.showUncoveredModal = true;
                this.$refs.uncoveredTransactionsForm.ShowModal(transactionData);
            },

            uncoveredTransactionModalClosed() {
                this.showUncoveredModal = false;
            },

            uncoveredTransactionModalSaved(correctionData) {
                this.showUncoveredModal = false;
                this.updateTaxCorrectionsAction(correctionData);
                this.loadDataFromStore();
                this.resetAllCharts();           
            },

            async forceChartRedraw() {
                //Hack for redrawing the chart when the side bar is collapsed so the user doesn't have to scroll
                this.showCharts = false;
                await serviceHelper.simulateAwait(true, 50);
                this.showCharts = true;
            },

            async resetAllCharts() {

                this.forceChartRedraw()

                //Reset charts
                if (this.$refs.gainLossPieChart)
                    this.$refs.gainLossPieChart.resetChart();
                if (this.$refs.gainLossBarChart)
                    this.$refs.gainLossBarChart.resetChart();
                if (this.$refs.gainLossLineChart)
                    this.$refs.gainLossLineChart.resetChart();
            },

            getTransactionRowBackground(record) {
                var rowClass = "recordNeutralStyle";
                if (record.gain > 0)
                    rowClass = "recordGainStyle";
                else if (record.gain < 0)
                    rowClass = "recordLossStyle";
                return rowClass;
            },

            getLogRowBackground(record) {
                var rowClass = "recordNeutralStyle";
                if (record.transactionType === "Error")
                    rowClass = "recordErrorStyle";
                return rowClass;
            },

            getDefaultRowStyle(record) {
                return "recordNeutralStyle";
            },

            async buySubscription() {
                try {
                    this.$refs.paymentDialog.ShowModal();
                }
                catch (err) {

                }            
            }
        },

        /* COMPUTED PROPERTIES */
        computed: {
            ...mapGetters(['currentUserData', 'toggleCount']),

            hasSubscription() { return this.$store.getters.hasRequiredRole('Premium User'); },

            allAssets() {
                //Retrieve a list of distinct assets from logs
                if (this.logs.length > 0) {
                    var assets = [...new Set(this.logs.map(a => a.asset).sort())];
                    assets.unshift("All Assets");
                    return assets;
                }
                else
                    return [];
            },

            filteredLogs() {
                //Filtered logs by asset
                var filteredLogs = [];
                if (this.logs.length > 0) {
                    if (this.selectedAssetLog === null || this.selectedAssetLog == "All Assets")
                        filteredLogs = this.logs;
                    else
                        filteredLogs = this.logs.filter(x => x.asset == this.selectedAssetLog);
                }
                return filteredLogs;
            },

            formattedRecords()
            {
                //Don't evaluate if user has recently signed out
                if (this.currentUserData === null)
                    return [];

                //Tax records with styling
                const taxRecords = cloneDeep(this.turboTaxRecords);

                //Add manual corrections to tax records
                this.currentUserData.taxData.forEach(
                    taxUpload => {
                        taxUpload.manualCorrections.forEach(
                            correction => {
                                if (correction.status !== "Rejected") {

                                    //Get uncovered transaction that was corrected
                                    var uncoveredTransaction = taxUpload.uncoveredTransactions.find(x => x.id === correction.id);
                                    var costBasis = parseFloat(uncoveredTransaction.quantity * correction.spot);
                                    var proceeds = parseFloat(uncoveredTransaction.quantity * uncoveredTransaction.price);
                                    var gain = proceeds - costBasis;

                                    //Parse to MM/DD/YYYY
                                    var timeStamp = format(new Date(uncoveredTransaction.timestamp), displayShortDateYearFormat);
                                    var correctionRecord =
                                    {
                                        "assetAmount": uncoveredTransaction.quantity,
                                        "assetName": uncoveredTransaction.asset,
                                        "receivedDate": timeStamp,
                                        "dateSold": timeStamp,
                                        "proceeds": proceeds.toFixed(2),
                                        "costBasis": costBasis.toFixed(2),
                                        "gain": gain.toFixed(2),
                                        "type": "Short Term"
                                    };
                                    taxRecords.push(correctionRecord);
                                }
                            })
                    });

                return taxRecords;

            },

            showExchange() { return this.selectedYear !== "Select Tax Year" },

            showTaxMethod() { return this.selectedExchange != "Select Exchange" && this.selectedExchange != "Robinhood" },

            showFileUploader() { return (this.selectedTaxMethod === "Select Tax Method" && this.selectedExchange === "Robinhood") || this.selectedTaxMethod !== "Select Tax Method" },

            showMainForm() {
                return this.hasResults && !this.showTaxWizard && !this.showUncoveredModal;
            },

            hasResults() {
                return this.currentUserData.taxData.length > 0;
            },

            taxSummary() {
                if (this.hasResults) {

                    var allTaxSummaries = [];

                    this.currentUserData.taxData.forEach(
                        taxUpload => {

                            var gainLoss = 0;
                            taxUpload.taxRecords.forEach(
                                record => {
                                    gainLoss += record.gain
                                });

                            var uncoveredCount = taxUpload.uncoveredTransactions.length;
                            var correctedCount = taxUpload.manualCorrections.length;

                            var isComplete = uncoveredCount === correctedCount ? true : false;
  
                            var formattedGainLoss = gainLoss.toFixed(2);
                            var exchangeText = taxUpload.exchange;

                            if (exchangeText === "Manual") {
                                exchangeText = taxUpload.manualExchange + " (Manual)";
                            }

                            var summary =
                            {
                                "uploadId": taxUpload.uploadId,
                                "year": taxUpload.taxYear,
                                "exchange": taxUpload.exchange,
                                "method": taxUpload.taxMethod,
                                "gain": "$" + formattedGainLoss,
                                "uncovered": uncoveredCount,
                                "corrected": correctedCount,
                                "isComplete": isComplete,
                                "exchangeText": exchangeText
                            };

                            allTaxSummaries.push(summary);
                        });

                    return allTaxSummaries;
                }

                return []; //No results, return no tax summary
            },

            totalGainLoss() {
                var gainLoss = 0;
                this.turboTaxRecords.forEach(
                    record => {
                        gainLoss += record.gain
                    });
                return gainLoss.toFixed(2);
            },

            chartTypeLabel() {
                if (this.chartType === "pie")
                    return "Gain/Loss Distribution";
                else if (this.chartType === "bar")
                    return "Gain/Loss Distribution";
                else if (this.chartType === "line")
                    return "Gain/Loss Over Time";
                else if (this.chartType === "table")
                    return "Gain/Loss Totals";
                return "";
            },

            downloadFileTitle() {
                if (this.hasSubscription)
                    return "Download your tax file";
                else
                    return "Upgrade to premium access to download your tax file";
            }
        }
    };
</script>

<style scoped>

    /deep/ .centeredCell {
        vertical-align: middle !important;
    }

    /deep/ .alignedCell {
        vertical-align: middle !important;
        text-align: center !important;
    }

/*    /deep/ .bg-secondary {
        background-color: var(--var-theme-color) !important;
    }*/

    .cardHeader {
        border-color: #616161;
        padding: 0px;
        margin: 0px;
        border-width: 0px;
        border-radius: 20px;
        background-color: white;
    }

    .customCardHeader {
        height: 30px;
        padding-top: 3px;
        padding-left: 15px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        /*background-color:gray !important;*/
    }

    .featureListItem {
        display: flex;
        align-items: center;
    }

    .chartTab {
        width: 100%;
        display: block;
        margin: 5px;
        padding: 5px;
        justify-content: normal;
    }

    .flex-container {
        display: flex;
        flex-direction: row;
    }

    /deep/ .recordGainStyle {
        background-color: #76E23D !important;
        color: black !important;
        border-bottom: 1.1px solid gray !important;
    }

    /deep/ .recordLossStyle {
        background-color: #9D2605 !important;
        color: white !important;
        border-bottom: 1.1px solid gray !important;
    }

    /deep/ .recordNeutralStyle {
        background-color: #BABFB8 !important;
        color: black !important;
        border-bottom: 1.1px solid gray !important;
    }

    /deep/ .recordErrorStyle {
        background-color: #EBBB3B !important;
        color: black !important;
        border-bottom: 1.1px solid gray !important;
    }

    .iconBlink {
        animation: blink 2s ease-in infinite;
    }

    @keyframes blink {
        from, to {
            opacity: 1
        }

        50% {
            opacity: 0
        }
    }

    /* Responsive layout  */
    @media (max-width: 800px) {
        .flex-container {
            flex-direction: column;
        }
    }

    /* Desktop */
    @media (min-width: 741px) {

        body.modal-open {
            overflow: hidden;
        }

        .desktopTransactionScroll {
            max-height: 38vh !important;
            overflow-y: auto;
        }

        .desktopLogScroll {
            max-height: 33vh !important;
            overflow-y: auto;
        }

        .desktopChartsScroll {
            max-height: 38vh !important;
            overflow-y: auto;
            width: 100%;
            display: inline-block;
            justify-content: center;
        }

        .chartButtonContainer {         
            float:right;
        }
    }

    /* Mobile */
    @media (max-width: 740.98px) {

        .chartButtonContainer {
            width:100%; 
            display:flex; 
            justify-content:center;
            margin:5px;
            padding:5px;
        }
    }

</style>