const downloadTurboTaxFile = function(exportData) {
    var arrData = exportData;
    //Format headers
    var header = Object.keys(arrData[0]).join(",");
    header = header.replace("assetAmount", "Asset Amount");
    header = header.replace("assetName", "Asset Name");
    header = header.replace("receivedDate", "Received Date");
    header = header.replace("dateSold", "Date Sold");
    header = header.replace("proceeds", "Proceeds (USD)");
    header = header.replace("costBasis", "Cost Basis (USD)");
    header = header.replace("gain", "Gain (USD)");
    header = header.replace("type", "Type");
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += [
            header,
            ...arrData.map(item => Object.values(item).join(","))
        ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

    const data = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("download", "export.csv");
    link.click();
}

export const downloadHelper = {
    downloadTurboTaxFile
};

